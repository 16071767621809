@charset "utf-8";

/* ===================================================================================================================

		common（共通）

=================================================================================================================== */

body {
	width: 100%;
	color: #333333;
	text-align: center;
	font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ ゴシック", "MS Gothic", sans-serif;
	font-weight: 500;
}


.orange{
	color: #ff6600;
}

.bold{
	font-weight: bold;
}

.underline{
	text-decoration: underline;
}


.max-width_set {
	max-width: 960px;
	margin: 0 auto;
	text-align: center;
}

.clearfix:after {
	content: " ";
	/* clearfixで出る下の余白を削除 */
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

* {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/*======================================================  BOX幅 ====================================================*/

.contents01_box {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}

/*フルサイズ*/

.contents02_box {
	width: 100%;
	margin: 0 auto;
}

.contents03_box, .contents04_box {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}

.contents1120_box {
	width: 100%;
	max-width: 1120px;
	margin: 0 auto;
}

.contentsFull_box {
	width: 100%;
	margin: 0 auto;
}

/* ===============================================

		1120px以下

=============================================== */

@media (max-width: 1280px) {
	/* 左右の余白 */
	.header_box, .contents1120_box, .contents_faq_box, .contents01_box, .contents02_box, .contents03_box, .contents04_box, .point_box {
		padding-left: 10px;
		padding-right: 10px;
	}
}

/*======================================================  見出し ===================================================*/

h1 {
	font-size: 36px;
	font-weight: bold;
	padding-top: 70px;
	padding-bottom: 70px;
}

/* ===============================================

		1120px以下

=============================================== */

@media (max-width: 1280px) {
	h1 {
		font-size: 32px;
		padding-top: 60px;
		padding-bottom: 50px;
	}
}

/* ===============================================

		767px以下

=============================================== */

@media (max-width: 767px) {
	h1 {
		font-size: 30px;
		padding-top: 35px;
		padding-bottom: 30px;
	}
}

/* ===============================================

		490px以下

=============================================== */

@media (max-width: 490px) {
	h1 {
		font-size: 24px;
		padding-top: 30px;
		padding-bottom: 28px;
	}
}

/* ===============================================

		320px以下

=============================================== */

@media (max-width: 320px) {
	h1 {
		font-size: 18px;
		padding-top: 25px;
		padding-bottom: 15px;
	}
}

/*================================================  もっと見る ボタン ==============================================*/

/* もっと見る ボタン上の余白 */

.button_box {
	margin-top: 60px;
}

/* もっと見る ボタン */

.button {
	display: block;
	max-width: 400px;
	margin: 0 auto;
	padding: 20px 40px;
	border-radius: 25px;
	background-color: #4e8f53;
	color: #fff;
	&:hover {
		opacity: 0.8;
	}
}

/* ボタンホバー */

/* ===============================================

		767px以下

=============================================== */

@media (max-width: 767px) {
	/* もっと見る ボタン上の余白 */
	.button_box {
		margin-top: 30px;
	}
	/* もっと見る ボタン */
	.button {
		width: 90%;
		font-size: 14px;
		padding: 20px 0;
	}
}

/* ===============================================

		320px以下

=============================================== */

@media (max-width: 320px) {
	/* もっと見る ボタン */
	.button {
		font-size: 11px;
	}
}

/*==================================================  パンくずリスト ===============================================*/

.pankuzu_box, .pankuzu_box_1120 {
	max-width: 1120px;
	margin: 0 auto;
	text-align: left;
	font-size: 13px;
	padding-left: 10px;
}

.pankuzu_box ul li, .pankuzu_box_1120 ul li {
	display: inline-block;
	text-align: left;
	color: #313220;
	padding-top: 20px;
}

.pankuzu_box ul li a, .pankuzu_box_1120 ul li a {
	display: block;
	color: #313220;
	text-decoration: underline;
}

.pankuzu_box ul li a:hover, .pankuzu_box_1120 ul li a:hover {
	text-decoration: none;
}

/* ===============================================

		1280px以上

=============================================== */

@media (min-width: 1280px) {
	.pankuzu_box, .pankuzu_box_1120, .point_box {
		padding-left: 0;
	}
}

/* ===============================================

		767px以下

=============================================== */

@media (max-width: 767px) {
	.pankuzu_box, .pankuzu_box_1120 {
		font-size: 11px;
		background: #ddd;
		margin-top: 5px;
	}
	.pankuzu_box ul li, .pankuzu_box_1120 ul li {
		padding: 10px 0;
	}
}

/* ===============================================

		320px以下

=============================================== */

@media (max-width: 320px) {
	/* パンくずが長い場合テキストが・・・になる */
	.pankuzu_box ul li, .pankuzu_box_1120 ul li {
		padding-top: 5px;
		max-width: 60px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}
}

/* ===================================================================================================================

		ヘッダー

=================================================================================================================== */

/* ヘッダー全体 */

.header_box {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	max-width: 960px;
	margin: 0 auto;
}

/* 横並び */
/* 横並び ロゴ画像BOX */

.head_left {
	width: 232px;
	float: left;
	vertical-align: bottom;
}

/* 横並び 問い合わせBOX(左) */

.head_right {
	float: right;
	// padding-top: 24px;
	padding-top: 18px;
	vertical-align: bottom;
}

/* ロゴ画像 */

.head_left img {
	display: block;
	width: 100%;
	vertical-align: bottom;
}

header{
	.header-sns{
		margin-top: 8px;	
		max-width: 290px;	
		display: block;
		width: auto;
		float:left;
		ul{
			li{
				border:none;
				border-right:1px dotted #ccc;
				padding-right: 15px;
				padding-left: 15px;

				img{

				}
				&:first-of-type{
					border-left:1px dotted #ccc;
				}
			}
		}
		p{
			font-size: 12px;
			text-align: left;
			color:#333;
			margin-top: 10px;	
			line-height: 1.2;	
		}
	}
}


@media screen and (max-width: 890px) {
	header{
		.header-sns{
			max-width: 140px;	
			
			p{
				margin-top: 5px;
				font-size: 10px;
			}

		}

		// br{
		// 	display: none;	
		// }
	}

	
}



.overhead{
  width: 100%;
  display: block;
  border-bottom: 1px solid #dcdcdc;
}

header .overhead  .header-sns{
  max-width: 100%;
}


.overhead .header-sns ul{
  float:left;
}
.overhead .header-sns ul+p{
  float:left;
  display: block;
  margin-top: 3px;
  margin-left: 20px;
  width:auto;
}
.overhead a.petrecruit{
  float:right;
  display: block;
  width: 160px;
	transition-duration:0.3s;
}
.overhead a.petrecruit:hover{
  opacity:0.8;

}


.overhead p.petrecruit{
  float:right;
	margin-top: 5px;
	margin-right: 8px;
  width: auto;
    font-size: 12px;
}

.overhead .petrecruit img{
  height:auto;
  max-width:100%;
}














/* 問い合わせボタン */

.info_button {
	a {
		display: block;
		max-width: 180px;
		background-color: #ed6d00;
		font-size: 16px;
		color: #fff;
		border-radius: 20px;
		padding: 10px 35px;
		&:hover {
			opacity: .7;
		}
	}
	i {
		font-size: 20px;
		padding-right: 15px;
	}
	float: left;
	margin-right: 20px;
}

/* メールアイコン */

/* 問い合わせボタン float */

/* 電話番号とか float */

.number_box {
	float: left;
	.number {
		color: #666;
		font-size: 28px;
		font-weight: bold;
		line-height: 16px;
		padding-top: 5px;
		text-align: left;
	}
	.time {
		font-size: 14px;
		font-weight: normal;
		padding-top: 8px;
		text-align: left;
	}
	.mail {
		font-size: 14px;
		font-weight: normal;
		padding-top: 8px;
		text-align: left;
		display: block;
	}
}

/* 電話番号 */

/* 受付時間 */

/* メール */

/* ===============================================

		1280px以下

=============================================== */

// @media (max-width: 1280px) {
	/* ロゴ画像 */
	.head_left img {
		width: 90%;
	}
// }

/* ===============================================

		960px以下

=============================================== */

@media (max-width: 960px) {
	/* 問い合わせBOX(右)の幅 */
	/* 電話番号 */
	.number_box .number {
		font-size: 23px;
		line-height: 16px;
	}
}

/* ===============================================

		768px以下

=============================================== */

@media (max-width: 768px) {
	.bxslider li .w-h1 {
		background-color: rgba(0, 0, 0, 0.3);
		h1 {
			font-weight: bold;
		}
	}
	/* ヘッダー全体 */
	.header_box {
		padding-top: 2px;
		padding-bottom: 3px;
	}
	/* 横並び解除 */
	.head_left {
		display: block;
	}
	.head_right {
		display: block;
		display: none;
	}
	/* 問い合わせBOX(右) 非表示 */
	/* ロゴ画像BOX */
	.head_left {
		width: 100%;
		img {
			width: 24%;
			margin: 0 auto;
		}
	}
	/* ロゴ画像 */
}

/* ===============================================

		550px以下

=============================================== */

@media (max-width: 550px) {
	/* ロゴ画像 */
	.head_left img {
		width: 140px;
	}
}

/* ===============================================

		320px以下

=============================================== */

@media (max-width: 320px) {
	/* ロゴ画像 */
	.head_left img {
		width: 120px;
	}
}

/* ===================================================================================================================

		NAVI(PCのみ)

=================================================================================================================== */

/* NAVI全体 */

.nav_box {
	width: 100%;
	background-color: #4e8f53;
	letter-spacing: -.40em;
	/* 文字間を詰めて隙間を削除する */
	ul {
		width: 100%;
		li {
			display: inline-block;
			letter-spacing: normal;
			/* 文字間を通常に戻す */
			// padding-right: 30px;
			// &:last-child {
			// 	padding-right: 0px;
			// }
			a {
				display: block;
				font-size: 15px;
				color: #fff;
				padding-top: 25px;
				padding-bottom: 25px;
				position: relative;
				&:after {
					content: "";
					height: 15px;
					border-right: 1px solid #fff;
					position: absolute;
					right: -15px;
					opacity: 0.3;
				}
			}
			&:last-child a:after {
				border-right: none;
			}
			a:hover {
				opacity: .7;
			}
		}
	}
}







/* NAVI全体 */
.nav_box {
  width: 100%;
  background-color: #4e8f53;
  letter-spacing: -.40em;
  /* 文字間を詰めて隙間を削除する */
}
.nav_box >ul {
  width: 100%;
  position: relative;
}
.nav_box >ul >li {
  display: inline-block;
  letter-spacing: normal;
  /* 文字間を通常に戻す */
  padding-right: 30px;
}
.nav_box >ul >li:last-child {
  padding-right: 0px;
}
.nav_box >ul >li >a {
  display: block;
  font-size: 15px;
  color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}



.nav_box >ul >li .submenu {
  display: none;

  position: absolute;
  top:65px;
  left:0px;
  width: 100%;


  z-index:1000;
  background-color: #133826;
}
.nav_box >ul >li .submenu *{
    box-sizing:border-box;
}
.nav_box >ul >li:hover .submenu {
  display: block;
}
.nav_box >ul >li:hover .submenu:before{
	content:"";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-bottom: 15px solid rgba(#133826,1);
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  top:-14px;
  left:50%;
  margin-left: -50px;
}

.nav_box .submenu li{
  display: inline-block;
  border-top:1px solid #555;
	margin-left: 20px;
}

.nav_box .submenu li a{
  display: block;
  padding-top: 20px; 
  padding-bottom: 20px;  
  color:#fff;
  font-size: 15px;  
      background-color: #333;
}
.nav_box .submenu li a:hover{
    opacity: .9;
}

.nav_box >ul >li >a:after {
  content: "";
  height: 15px;
  border-right: 1px solid #fff;
  position: absolute;
  right: -15px;
  opacity: 0.3;
}
.nav_box >ul >li:last-child a:after {
  border-right: none;
}
.nav_box >ul >li >a:hover {
  opacity: .7;
}









/* 横並びに 右余白 */

/* リンク 高さ */

/* ===============================================

		960px以下

=============================================== */

@media (max-width: 960px) {
	/* フォントサイズ */
	.nav_box ul li a {
		font-size: 13px;
	}


	.nav_box >ul >li .submenu {
	  top:62px;
	  left:0px;
	}

	.nav_box >ul >li:hover .submenu:before{
	  top:-15px;
	  left:50%;
	  margin-left: -50px;
	}







}

/* ===============================================

		767px以下

=============================================== */

@media (max-width: 767px) {
	/* ---------------------------------
		smpで非表示
	---------------------------------- */
	/* NAVI */
	.nav_box {
		display: none;
	}
}

/* ===================================================================================================================

		フッター

=================================================================================================================== */

footer a:hover {
	opacity: .7;
}

/*================================================ footer ペットの段 ===============================================*/

/* 背景BOX 上margin  position基準点*/

.info_box {
	width: 100%;
	margin-top: 110px;
	background-color: #f0f0eb;
	position: relative;
}

/* 中身の幅*/

.info_box_inner {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	position: relative;
	top: -25px;
	left: 0;
	letter-spacing: -.40em;
	/* 文字間を詰めて隙間を削除する */
}

/* 横並び */

.pet_imgbox, .footer_info {
	display: inline-block;
	letter-spacing: normal;
	/* 文字間を通常に戻す */
	vertical-align: middle;
}

/* 画像BOX*/

.pet_imgbox {
	width: 50%;
	margin: 0 auto;
}

/* 画像 */

.info_box img {
	width: 100%;
	max-width: 500px;
	float: right;
}

/* 問い合わせBOX */

.footer_info {
	width: 50%;
	margin: 0 auto;
	padding-top: 50px;
	padding-left: 30px;
	div {
		&:first-child {
			font-size: 24px;
			font-weight: bold;
			text-align: left;
		}
		&:nth-child(2) {
			font-size: 40px;
			font-weight: bold;
			text-align: left;
			padding: 10px 0 10px 0;
		}
		&:nth-child(3) {
			font-size: 24px;
			text-align: left;
		}
	}
	.petrecruit{
		margin-top: 10px;
		a{
			display: inline-block;
			width: 240px;
			vertical-align:middle;
			margin-right: 10px;
			img{
			  height:auto;
			  max-width:100%;
			}
		}
		p{
			display: inline-block;

			margin-top: 15px;
			font-size: 15px;
			vertical-align:middle;
			text-align:left;
		}
	}

}

/* お気軽にお問合せください */

/* 電話番号 上下余白 */

/* 受付時間 */

/* 問い合わせボタン */

.footer_button {
	a {
		display: block;
		// width: 240px;
		text-align: center;
		background-color: #ed6d00;
		color: #fff;
		font-size: 18px;
		border-radius: 6px;
		padding: 14px 0;
		// margin-right: 21px;
		margin-top: 20px;


			width: auto;
			min-width: 240px;
			margin-right: auto;
			margin-left: auto;



	}
	i {
		font-size: 22px;
		padding-right: 10px;
		text-align: center;
	}
}

/* メールアイコン */

/* ===============================================

		1280px以下

=============================================== */

@media (max-width: 1280px) {
	/* 中身の幅*/
	.info_box_inner {
		width: 90%;
	}
	/* 問い合わせBOX */
	.footer_info {
		padding-left: 20px;
	}
	/* 画像 */
	.info_box img {
		max-width: 400px;
	}
	/* お気軽にお問合せください */
	.footer_info div {
		&:first-child {
			font-size: 20px;
		}
		&:nth-child(2) {
			font-size: 28px;
			padding: 5px 0 5px 0;
		}
	}
	.footer_info{
		.petrecruit{
			text-align:left;
		}
	}
	/* 電話番号 上下余白 */
}

/* ===============================================

		960px以下

=============================================== */

@media (max-width: 960px) {
	/* 画像 */
	.info_box img {
		max-width: 350px;
	}
}

/* ===============================================

		768px以下

=============================================== */

@media (max-width: 768px) {
	/* footer 上margin */
	.info_box {
		margin-top: 70px;
	}
	/* 横並び解除 */
	.pet_imgbox, .footer_info {
		display: block;
	}
	/* 問合せボタン非表示 */
	.footer_button {
		display: inline-block;

	}
	/* 画像 */
	.info_box img {
		width: 60%;
		max-width: 500px;
		float: none;
	}
	/* 問い合わせBOX */
	.footer_info {
		width: 90%;
		padding-left: 0;
		padding-top: 15px;
		div {
			&:first-child {
				font-size: 22px;
				text-align: center;
			}
			&:nth-child(2) {
				font-size: 32px;
				text-align: center;
				padding: 10px 0 5px 0;
			}
			&:nth-child(3) {
				font-size: 20px;
				text-align: center;
			}
		}
		.petrecruit{
			text-align: center;
			a{
				display: block;
				margin-left: auto;
				margin-right: auto;
				margin-top: 20px;
				img{
					width: 100%;
				}
			}
			p{
				display: inline-block;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
	/* お気軽にお問合せください */
	/* 電話番号 上下余白 */
	/* 受付時間 */
}

/* ===============================================

		550px以下

=============================================== */

@media (max-width: 550px) {
	/* footer 上margin */
	.info_box {
		margin-top: 50px;
	}
	/* お気軽にお問合せください */
	.footer_info div {
		&:first-child {
			font-size: 18px;
		}
		&:nth-child(2) {
			padding: 8px 0 3px 0;
		}
		&:nth-child(3) {
			font-size: 18px;
			text-align: center;
		}
	}
	/* 電話番号 上下余白 */
	/* 受付時間 */
}

/* ===============================================

		320px以下

=============================================== */

@media (max-width: 320px) {
	/* お気軽にお問合せください */
	.footer_info div {
		&:first-child {
			font-size: 14px;
		}
		&:nth-child(2) {
			font-size: 28px;
			padding: 3px 0 0 0;
		}
		&:nth-child(3) {
			font-size: 15px;
			text-align: center;
		}
	}
	/* 電話番号 上下余白 */
	/* 受付時間 */
}

/*=================================================== ページトップ =================================================*/

.page-top {
	background-color: #6cb271;
	i {
		font-size: 52px;
		font-weight: 100;
		color: #fff;
	}
}

.move-page-top {
	display: block;
	padding-bottom: 5px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
}

/* ===============================================

		768px以下

=============================================== */

@media (max-width: 768px) {
	/*  非表示 */
	.page-top {
		display: none;
	}
}

/*================================================= フッターメニュー ===============================================*/

/* フッターメニューBOX 背景色 */

.footmenu_box {
	width: 100%;
	background-color: #4e8f53;
	padding-top: 40px;
	padding-bottom: 40px;
	ul {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		letter-spacing: -.40em;
		/* 文字間を詰めて隙間を削除する */
		li {
			display: inline-block;
			letter-spacing: normal;
			/* 文字間を通常に戻す */
			margin-right: 20px;
			a {
				display: block;
				font-size: 15px;
				line-height: 22px;
				color: #fff;
				position: relative;
				&:after {
					content: "";
					height: 15px;
					border-right: 1px solid #fff;
					position: absolute;
					top: 4px;
					right: -10px;
					opacity: 0.3;
				}
			}
			&:last-child a:after {
				border-right: none;
			}
		}
	}
}

/* 横並び */

/* リンク */

.w-pagetop {
	height: 57px;
	display: block;
	position: relative;
	.page-top {
		width: 100%;
		&.flow {
			position: fixed;
			bottom: 0;
			z-index: 20000;
		}
	}
}

/* ===============================================

		960px以下

=============================================== */

@media (max-width: 960px) {
	/* 文字右余白 */
	.footmenu_box ul li {
		margin-right: 25px;
		a {
			font-size: 13px;
		}
	}
	/* フォントサイズ */
}

/* ===============================================

		768px以下

=============================================== */

@media (max-width: 768px) {
	/* 背景色 */
	.footmenu_box {
		background-color: #4e8f53;
		padding-top: 25px;
		padding-bottom: 35px;
		ul li {
			display: block;
			margin: 0 auto;
			a {
				&:after {
					display: none;
				}
				display: block;
				font-size: 14px;
				line-height: 26px;
				font-weight: 500;
			}
		}
	}
	/* 横並び */
	/* リンク */
}

/* ===============================================

		550px以下

=============================================== */

@media (max-width: 550px) {
	/* リンク */
	.footmenu_box ul li a {
		font-size: 15px;
		line-height: 28px;
	}
}

/* ===============================================

		320px以下

=============================================== */

@media (max-width: 320px) {
	/* リンク */
	.footmenu_box ul li a {
		font-size: 13px;
		line-height: 22px;
	}
}

/* ===================================================================================================================

		copyright

=================================================================================================================== */

.footer > .copyright {
	background-color: #4e8f53;
	color: #fff;
	font-size: 12px;
	padding-bottom: 20px;
}




/* ===================================================================================================================

		edge

=================================================================================================================== */
footer{
	.edge{
		display: block;
		max-width: 1120px;
		margin-left:auto;
		margin-right:auto;
		height: 50px;
		.copyright{
			font-size: 12px;
			color:#333;
			height: 100%;
			float:left;
		}
		.sns{
			float:right;
			height: 100%;
			&>p{
				float:left;
				font-size: 12px;
				color:#333;
				height: 100%;
				margin-right: 15px;
			}
			&>ul{
				height: 100%;
				float:right;
				li{
					

					a{
						padding-left:12px;
						padding-right: 12px;
						display: block;
						border-right:1px dotted #ccc;
						height: 24px;
						span{

						}
					}
					&:first-of-type{
						a{
							border-left:1px dotted #ccc;
						}
					}
				}

			}
		}
	}
}

/* ===============================================

		768px以下

=============================================== */

@media (max-width: 768px) {
	footer > .edge {
		padding-bottom: 90px;
		height: auto;
	}

	footer{
		.edge{
			display: block;
			max-width: 1120px;
			margin-left:auto;
			margin-right:auto;
			.copyright{
				height: 30px;
				float:none;
				margin-left:auto;
				margin-right:auto;
				padding-top: 20px;
				padding-bottom: 20px;
			}
			.sns{
				float:none;
				&>p{
					float:none;
					padding-bottom: 20px;
					padding-left: 10px;
					padding-right: 10px;
					margin-left:auto;
					margin-right:auto;
					letter-spacing: -1px;
				}
				&>ul{
					margin-left:auto;
					margin-right:auto;
					float:none;
					li{
						a{
							padding-left:12px;
							padding-right: 12px;
							display: block;
							border-right:1px dotted #ccc;
							height: 24px;
						}
						&:first-of-type{
							a{
								border-left:1px dotted #ccc;
							}
						}
					}
				}
			}
		}
	}
}


/* ===================================================================================================================

		ハンバーガーメニュー

=================================================================================================================== */

/* ===============================================

		768px以上

=============================================== */

@media (min-width: 768px) {
	/* ハンバーガーメニュー */
	.smp_navi_box {
		display: none;
	}
}

/* ===============================================

		768px以下

=============================================== */

@media (max-width: 768px) {
	/* フッター全体　固定 */
	#footer {
		width: 100%;
		position: fixed;
		bottom: -5px;
		a {
			display: block;
			color: #fff;
			padding: 15px 0;
			/* borderにpaddingが影響しない */
		}
	}
	/* left+rightの親　横並び */
	.smp_navi_box {
		background-color: #28722d;
		.left {
			width: 50%;
			float: left;
			border-right: 1px solid #4e8f53;
		}
	}
	/* 左 */
	.left {
		.item {
			font-size: 14px;
			font-weight: bold;
		}
		div i {
			font-size: 30px;
			padding-top: 2px;
		}
	}
	/* ハンバーガーマーク */
	/* 右 */
	.smp_navi_box .right {
		width: 50%;
		float: left;
	}
	.right {
		.item {
			font-size: 14px;
			font-weight: bold;
		}
		div i {
			font-size: 40px;
			line-height: 30px;
		}
	}
	/* TOPマーク */
	.w-pagetop {
		height: auto;
	}
}


.br940{
	display: none;
}
@media (max-width: 940px) {
	.br940{
		display:inline;
	}
	.overhead .header-sns ul + p{
		margin-top: 0;
	}
	.overhead p.petrecruit{
		font-size: 10px;
		margin-top: 10px;
	}
	.overhead .petrecruit img{
		max-height:25px;
		width:auto;
		margin-top: 6px;
	}
}













/*スマホメニュー*/

@media (max-width: 767px) {
	/*==================== ハンバーガーメニュー ====================*/
	/* line 735, sass/style.scss */
	.smp_navi_box {
		/*    background-color: #4e8f53;*/
		padding: 10px 0;
		.left, .right {
			width: 50%;
		}
		a {
			color: #fff;
		}
	}
	/* line 738, sass/style.scss */
	/* line 741, sass/style.scss */
	/* line 746, sass/style.scss */
	.left {
		.item {
			font-size: 14px;
			font-weight: bold;
		}
		div i {
			font-size: 30px;
		}
	}
	/* line 750, sass/style.scss */
	/* line 755, sass/style.scss */
	.right {
		.item {
			font-size: 14px;
			font-weight: bold;
		}
		div i {
			font-size: 40px;
			line-height: 30px;
		}
	}
	/* line 759, sass/style.scss */
	/* line 990, sass/style.scss */
	header {
		width: 100%;
		height: 60px;
		display: block;
		background-color: #fff;
		.header_box {
			height: 60px;
			margin-top: 0;
			padding-bottom: 0;
		}
		.head_left {
			height: 60px;
			img {
				width: auto;
			}
		}
		.max-width_set {
			width: 100%;
			height: 60px;
			display: block;
			background-color: #fff;
			position: fixed;
			z-index: 10001;
		}
		figure {
			height: 60px;
			padding-top: 10px;
			padding-bottom: 10px;
			img {
				max-height: 100%;
				width: auto;
			}
		}
	}
	/* line 995, sass/style.scss */
	/* line 1000, sass/style.scss */
	/* line 1002, sass/style.scss */
	/* line 1006, sass/style.scss */
	/* line 1014, sass/style.scss */
	/* line 1018, sass/style.scss */
	/* line 1026, sass/style.scss */
	.header_box {
		margin-bottom: 0;
	}
	/* line 1030, sass/style.scss */
	.smp_navi_box {
		padding: 0px 0;
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 70px;
		z-index: 10001;
		display: block;
		.right {
			padding: 15px 0;
			display: block;
			overflow: hidden;
			box-sizing: border-box;
			/*    height: 66px;*/
			float: left;
		}
		.left {
			padding: 15px 0;
			box-sizing: border-box;
			display: block;
			/*    height: 66px;*/
			float: left;
		}
		.smp_menu_open {
			display: block;
			/*    background-color: #4e8f53;*/
		}
		// .w-sns{
		// 	height:110px;
		// }
		.petrecruit{
			max-width:200px;
			height:auto;
			text-align: center;
			margin-right: auto;
			margin-left: auto;
			margin-top: 20px;
			margin-bottom: 10px;
		}
		.sns{
			padding-top: 10px;	
			padding-bottom: 10px;	
			margin-right:auto;
			margin-left:auto;
			// max-width: 285px;	
			box-sizing:border-box;
			display: block;
			width: 100%;
			text-align: center;
			padding-left: 15px;	
			ul{
				margin-right:auto;
				margin-left:auto;
				li{
					border:none;
					border-right:1px dotted #ccc;
					padding-right: 15px;
					padding-left: 15px;

					img{

					}
					&:first-of-type{
						border-left:1px dotted #ccc;
					}
				}
			}
			p{
				font-size: 12px;
				text-align: left;
				color:#333;
				margin-top: 10px;	
				line-height: 1.2;
				text-align: center;
				letter-spacing: -1px;
			}

		}



	}
	/* line 1046, sass/style.scss */
	/* line 1053, sass/style.scss */
	/* line 1061, sass/style.scss */
	.w-sp_nav {
		width: 100%;
		height: 0;
		background-color: #fff;
		position: absolute;
		z-index: -1;
		/*    bottom: 60px;*/
		bottom: 70px;
		display: block;
		overflow-y: scroll;
	}
	/* line 1073, sass/style.scss */
	.sp_nav> li {
		height: 50px;
		box-sizing: border-box;
		border-bottom: 1px solid #d8d8d4;
		&:first-of-type {
			border-top: 1px solid #d8d8d4;
		}
		a {
			height: 100%;
			width: 100%;
			display: table;
			vertical-align: middle;
			color: #000;
			line-height: 1;
			span {
				display: table-cell;
				vertical-align: middle;
				font-weight: bold;
			}
		}
		&.cv {
			height: auto;
			padding: 35px 0;
			.inquiry {
				margin: 0 auto 10px;
				color: #fff;
				width: 126px;
				height: 30px;
				background-color: #ed6d00;
				border-radius: 15px;
				line-height: 1.6;
				font-size: 14px;
				i {
					font-size: 20px;
					margin-right: 8px;
				}
			}
			.tel {
				font-weight: bold;
				font-size: 35px;
				margin-bottom: 10px;
			}
		}
	}


}



  /* line 1073, sass/style.scss */
  .sp_nav >li {
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d4;
  }

  .sp_nav >li {
    height: auto;
    min-height:50px;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d4;
  }

  .sp_nav >li:first-of-type {
    border-top: 1px solid #d8d8d4;
  }
  .sp_nav >li >a {
    height: 50px;
    width: 100%;
    display: table;
    vertical-align: middle;
    color: #000;
    line-height: 1;
  }
  .sp_nav >li >a span {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
  }
  .sp_nav >li  .submenu{
    padding-bottom: 10px;
  }
  .sp_nav >li  .submenu li{
    
  }
  .sp_nav >li  .submenu li a{
    color:#333!important;
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
  }


  .sp_nav >li.cv {
    height: auto;
    padding: 35px 0;
  }
  .sp_nav >li.cv .inquiry {
    margin: 0 auto 10px;
    color: #fff;
    width: 126px;
    height: 30px;
    background-color: #ed6d00;
    border-radius: 15px;
    line-height: 1.6;
    font-size: 14px;
  }
  .sp_nav >li.cv .inquiry i {
    font-size: 20px;
    margin-right: 8px;
  }
  .sp_nav >li.cv .tel {
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 10px;
  }


@media (min-width: 768px) {
	.hidden-md-up {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.hidden-sm-down {
		display: none !important;
	}
}



//calendar
aside{
	.calendar{
		table#wp-calendar {
			table-layout: fixed;
			width: 100%;
			margin: 0px auto 0 auto;
			padding: 0;
			border-collapse: collapse;
			border-spacing: 0;
			font-size: 12px;
		}
		table#wp-calendar caption {
			padding: 10px 0 8px;
			width: auto;
			text-align: center;
			font-weight: bold;
			background-color: #fff;
			border: thin solid #ccc;
		}
		table#wp-calendar #today {
			font-weight: bold;
			background-color: #ff6600;
			border: thin solid #ccc;
			color:#fff;
			z-index: 1;
		}
		table#wp-calendar .pad {
			font-weight: bold;
			background-color: #fff9f9;
		}
		table#wp-calendar th,
		table#wp-calendar td {
			padding: 2px auto;
			vertical-align: middle;
			text-align: center;
		}
		table#wp-calendar td {
			line-height: 1.8;
			border: thin solid #ccc;
			background-color: #fff;
		}
		table#wp-calendar th {
			font-style: normal;
			font-weight: bold;
			color: #fff;
			border-left: thin solid #ccc;
			border-right: thin solid #ccc;
			background-color: #ff6600;
			padding-top: 5px;
			padding-bottom: 5px;
		}
		table#wp-calendar a {
			font-size: 12px;
			color: #00a0dd;
			padding: 0;
			text-decoration: underline;
		}
	}
}



